import ReactSocialReducers from 'resources/../vendor/z5internet/react-social/src/resources/assets/js/src/reducers.js';

const post_rotator = (state = {}, action) => {
	switch (action.type) {
		case 'SAVE_POST_ROTATOR':
			let data = action.data;

			let da = Object.keys(data.languages).map((code) => {
				return { value: code, label: data.languages[code] };
			});

			da.unshift({ value: '', label: '', disabled: true });
			da.unshift({ value: 'ru', label: data.languages.ru });
			da.unshift({ value: 'fr', label: data.languages.fr });
			da.unshift({ value: 'de', label: data.languages.de });
			da.unshift({ value: 'es', label: data.languages.es });
			da.unshift({ value: 'en', label: data.languages.en });

			data.languages = da;

			return Object.assign({}, data);

		default:
			return state;
	}
};

const postPromoteInitialState = {
	cl: [0, 0, '', 0],
	clickOnNext: 0,
	countdown: 0,
	data: {},
	nextData: undefined,
	processCheck: undefined,
	show_profile: undefined,
};

const postPromoteState = (state = postPromoteInitialState, action) => {
	switch (action.type) {
		case 'CLICK_NEXT_BUTTON_POSTPROMOTE':
			state = Object.assign({}, state); // Need this otherwise componentDidUpdate won't compare previous props properly

			const e = action.e;

			const det = [' - '];

			let t;

			switch (e.type) {
				case 'mousedown':
					t = 'md';
					det[0] = e.pageX;
					det[1] = e.pageY;
					break;
				case 'click':
					t = 'cl';
					det[0] = e.pageX;
					det[1] = e.pageY;
					break;
				case 'keydown':
					t = 'kd';
					det[2] = e.keyCode || e.which;
					break;
				default:
					det[0] = e.pageX;
					det[1] = e.pageY;
					t = e.type;
					break;
			}

			det[3] = t;

			state.cl = det;

			if (state.nextData) {
				state.data = state.nextData;
				state.clickOnNext++;
			} else {
				state.data.m1 = 'loading';
			}

			delete state.nextData;

			break;

		case 'POSTPROMOTE_SET_COUNTDOWN_TIMER':
			state = Object.assign({}, state); // Need this otherwise componentDidUpdate won't compare previous props properly

			state.countdown = action.countdown ?? state.data.timer;

			break;

		case 'REDUCE_SURFBAR_COUNTDOWN_TIMER':
			state = Object.assign({}, state); // Need this otherwise componentDidUpdate won't compare previous props properly
			if (state.countdown > 0) {
				state.countdown--;
			}

			break;

		case 'SHOW_SURF_PROFILE':
			state.show_profile = action.profileId;
			break;

		case 'POSTPROMOTE_STORE_DATA':
			/*
			if (action.data.posts) {
				action.data.posts = action.data.posts.map((post) => {
					post.inDeck = true;
					post.animate = false;
					return post;
				});
			}

			if (state.data && state.data.posts && state.data.posts.length > 0) {
				action.data.posts = state.data.posts.concat(action.data.posts);
			}
*/

			state = Object.assign({}, state); // Need this otherwise componentDidUpdate won't compare previous props properly

			state.processCheck = undefined;

			if (
				(0 && !action.data.codes) ||
				!state.data.posts ||
				state.data.posts.length == 0 ||
				state.data.m1 === 'loading'
			) {
				if (state.data.m1 === 'loading') {
					state.clickOnNext++;
				}
				state.data = action.data;
			} else {
				state.nextData = action.data;
			}

			break;

		case 'POSTPROMOTE_PROCESS_CHECK':
			state.processCheck = undefined;

			if (action.processCheck) {
				state.processCheck = action.processCheck;
			} else {
				state.data.codes = undefined;
			}

			break;

		case 'POSTPROMOTE_SMALL_SCREEN':
			state.data.message = [null, null, 'small_screen'];
			break;

		case 'POSTPROMOTE_UPDATE_POSTS':
			state.data.posts = action.posts;
			break;

		case 'POSTPROMOTE_RESET':
			state = postPromoteInitialState;
			break;

		default:
			return state;
	}

	return JSON.parse(JSON.stringify(state));
};

const postPromoteLoading = (
	state = {
		loading: true,
		reloading: false,
	},
	action
) => {
	switch (action.type) {
		case 'POSTPROMOTE_RESET':
			return {
				loading: true,
				reloading: false,
			};
			break;
		case 'POSTPROMOTE_SET_LOADING':
			let change = false;

			if (
				action.loading !== undefined &&
				state.loading !== action.loading
			) {
				state = Object.assign({}, state); // Need this otherwise componentDidUpdate won't compare previous props properly

				change = true;
				state.loading = action.loading;
			}

			if (
				action.reloading !== undefined &&
				state.reloading !== action.reloading
			) {
				state = Object.assign({}, state); // Need this otherwise componentDidUpdate won't compare previous props properly

				change = true;
				state.reloading = action.reloading;
			}

			if (!change) {
				return state;
			}

			break;
	}

	return JSON.parse(JSON.stringify(state));
};

const ViewPostsCountdown = (state = {}, action) => {
	switch (action.type) {
		case 'PostRotatorSetCountdown':
			state.timer = action.timer;

			return Object.assign({}, state);

		case 'PostRotatorStartCountdown':
			state.restart = new Date().getTime();

			return Object.assign({}, state);

		case 'ResetPostRotator':
			return {};

		default:
			return state;
	}
};

const surfbarComponentID = (state = 0, action) => {
	switch (action.type) {
		case 'SET_SURFBAR_COMPONENT_ID':
			return new Date().getTime();

		default:
			return state;
	}
};

const liveData = (state = {}, action) => {
	switch (action.type) {
		case 'STORE_LIVE_DATA':
			state = Object.assign(state, action.data);

			return Object.assign({}, state);

		default:
			return state;
	}
};

export default Object.assign({}, ReactSocialReducers, {
	liveData,
	post_rotator,
	postPromoteLoading,
	postPromoteState,
	surfbarComponentID,
	ViewPostsCountdown,
});

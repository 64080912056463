import React, { Component } from 'react';

import RecordFeedActivity from '../../js/utils/recordFeedActivity';

class PreHeader extends Component {
	render() {
		return <RecordFeedActivity />;
	}
}

export default PreHeader;
